exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-my-writings-jsx": () => import("./../../../src/pages/my-writings.jsx" /* webpackChunkName: "component---src-pages-my-writings-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-workshops-jsx": () => import("./../../../src/pages/workshops.jsx" /* webpackChunkName: "component---src-pages-workshops-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-project-page-jsx": () => import("./../../../src/templates/project-page.jsx" /* webpackChunkName: "component---src-templates-project-page-jsx" */)
}

